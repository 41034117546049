import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import {
	VerticalTimeline,
	VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

const Updates = () => {
	return (
		<>
			<Navbar />
			<VerticalTimeline lineColor={"#000000"}>
				<VerticalTimelineElement
					className="vertical-timeline-element--work"
					contentStyle={{
						background: "#3837FF",
						color: "#fff",
					}}
					yarbn
					contentArrowStyle={{
						borderRight: "7px solid  #3837FF",
					}}
					date="April - 2022"
				>
					<h3 className="vertical-timeline-element-title">Release</h3>
					<h4 className="vertical-timeline-element-subtitle">
						Versie 1.0.2
					</h4>
					<p>Enkele bugs en algemene verbeteringen doorgevoerd</p>
				</VerticalTimelineElement>
				<VerticalTimelineElement
					className="vertical-timeline-element--work"
					date="Maart - 2022"
				>
					<h3 className="vertical-timeline-element-title">Release</h3>
					<h4 className="vertical-timeline-element-subtitle">
						Versie 1.0
					</h4>
					<p>Initiele release</p>
				</VerticalTimelineElement>
			</VerticalTimeline>
			<Footer />
		</>
	);
};

export default Updates;
